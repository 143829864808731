import * as React from 'react';
import { styled } from '@mui/system';
import {Tabs as TabsUnstyled} from '@mui/base/Tabs';
import {TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { TabPanel as TabPanelUnstyled} from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { useLocation } from 'react-router';
import {
  GearIcon,
  HomeIcon,
  PeopleIcon,
  VersionsIcon,
  CodeIcon,
  PersonAddIcon,
  BlockedIcon
} from '@primer/octicons-react'
import { Tab as TabUnstyled, tabClasses } from '@mui/base/Tab';
import { Link } from 'react-router-dom';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(TabUnstyled)(({theme}) => `
color: white;
cursor: pointer;
font-size: 0.875rem;
text-decoration: none;
display: grid;
align-items: center;
gap: 10px;
font-weight: bold;
background-color: transparent;
padding: 12px 16px;
margin: 6px 6px;
border: none;
border-radius: 5px;
display: flex;
justify-content: flex-start;

&:hover {
  background-color: ${blue[400]};
}
&:focus {
  color: #fff;
  border-radius: 3px;
  outline: 2px solid ${blue[200]};
  outline-offset: 2px;
}

&.${tabClasses.selected} {
  background-color: ${blue[50]};
  color: ${blue[600]};
  transition-duration: .2s;
}

&.${buttonClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`);

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(({theme}) => `
min-width: 320px;
background-color: ${theme.palette.primary.main};
border-radius: 8px;
height: 100%;
display: flex;
flex-flow: column;
align-items: stretch;
justify-content: flex-start;
align-content: space-between;
`);

export default function Navbar() {
  const {pathname} = useLocation()
  const tabs = {
    '/': 0,
    '/users': 1,
    '/newUser': 2,
    '/logs': 3,
    '/sessions': 4,
    '/blacklist': 5,
  }
  return (
    <TabsUnstyled style={{height: '100%'}} defaultValue={tabs[pathname]}>
        <TabsList>
          <Tab label="Home" index={0} slots={{ root: Link }} to={"/"}><HomeIcon/> Home</Tab>
          <Tab label="Users" index={1} slots={{ root: Link }}  to={"/users"}><PeopleIcon/> Users</Tab>
          <Tab label="Add user" index={3} slots={{ root: Link }} to={"/newUser"}><PersonAddIcon/> Add user</Tab>
          <Tab label="Logs" index={4} slots={{ root: Link }} to={"/logs"}><CodeIcon/> Logs</Tab>
          <Tab label="Sessions" index={2} slots={{ root: Link }} to={"/sessions"}><GearIcon/> Sessions</Tab>
          <Tab label="Blacklist" index={5} slots={{ root: Link }} to={"/blacklist"}><BlockedIcon/> Blacklist</Tab>
        </TabsList>
    </TabsUnstyled>
  );
}