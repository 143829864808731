import React, { useEffect, useState } from 'react';
import {Avatar, Paper} from '@mui/material';
import {
  DataGrid, GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import {deleteSession, deleteUser, getUsers} from "../api";
import Button from "../components/Button";
import {enqueueSnackbar} from "notistack";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

export const UsersPage = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      editable: false,
    },
    {
      field: 'token',
      headerName: 'Token',
      width: 350,
      editable: false,
      sortable: true,
    },
    {
      field: 'sessions',
      headerName: 'Sessions',
      sortable: true,
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Last activity',
      sortable: true,
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: true,
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (el) => {
        return [
          <Button onClick={() => deleteUserAction(el.row?._id)}>DEL</Button>
        ]
      }
    }
  ];
  const [rows, setRows] = useState([]);
  useEffect(() =>  {
    getUsers().then(res => {
      setRows(res?.users)
    }).catch(() => {
      setRows([])
    })
  }, [])
  const deleteUserAction = async (userId) => {
    const query = await deleteUser(userId);
    if(query.success) {
      setRows(prev => prev.filter(it => it._id !== userId))
      return enqueueSnackbar('Done.', { variant: 'success' });
    }
    enqueueSnackbar(query.message, { variant: 'error' })
  }
    return(<Paper sx={{height: '100%'}}>
    <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row?._id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      </Paper>)
}