import { Axios } from '../libs/axios';

export const newUser = async (user) => {
  const { data } = await Axios.post('/api/user/newUser', user);
  return data;
}

export const getSites = async () => {
  const { data } = await Axios.get('/api/settings/getSites');
  return data;
}

export const removeSite = async (site) => {
  const { data } = await Axios.post('/api/settings/removeSite', site);
  return data;
}

export const getUsers = async () => {
  const { data } = await Axios.get('/api/admin/user/getUsers');
  return data;
}

export const getLogs = async () => {
  const { data } = await Axios.get('/api/logs/getLogs');
  return data;
}

export const getUserBlack = async () => {
  const { data } = await Axios.get('/api/blacklist/getUsers');
  return data;
}

export const addUsersBlack = async (steamIds) => {
  const { data } = await Axios.post('/api/blacklist/addUsers', { steamIds});
  return data;
}

export const getOptions = async () => {
  return await Axios.get('/api/settings/getOptions');
}

export const saveOptions = async (site) => {
  const { data } = await Axios.post('/api/settings/editOptions', { ...site });
  return data;
}

export const newSession = async (session) => {
  const { data } = await Axios.post('/api/sessions/newSession', session);
  return data;
}
export const getSessions = async () => {
  const { data } = await Axios.get('/api/sessions/getSessions');
  return data;
}

export const deleteSession = async (session) => {
  const { data } = await Axios.post('/api/sessions/deleteSession', session);
  return data;
}

export const editUserOptions = async (options) => {
  const { data } = await Axios.post('/api/user/editOptions', {options});
  return data;
}

export const deleteUser = async (userId) => {
  const { data } = await Axios.post('/api/user/removeUser', {userId});
  return data;
}
