import React from 'react';
import { ThemeProvider } from '@mui/system';
import { CssBaseline } from "@mui/material";
import './App.css';
import { ProvideAuth } from './hooks/AuthHook';
import { defaultTheme } from './themes';
import { Routing } from './Routing';
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <div className="App">
      <SnackbarProvider>
        <ProvideAuth>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Routing/>
          </ThemeProvider>
        </ProvideAuth>
      </SnackbarProvider>
    </div>
  );
}
export default App;
