import { Box } from '@mui/material';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Navbar from './components/Navbar/index';
import {UsersPage} from './pages/UsersPage';
import {SettingsPage} from './pages/SettingsPage';
import ResponsiveAppBar from './components/AppBar/index';
import { HomePage } from './pages/HomePage';
import useAuth from './hooks/AuthHook'
import { SitePage } from './pages/SitesPage';
import {PromoPage, SessionsPage} from "./pages/SessionsPage";
import DesignPage from "./pages/DesignPage";
import Input from "./components/Input";
import Button from "./components/Button";
import {LogsPage} from "./pages/LogsPage";
import {BlacksPage} from "./pages/BlacksPage";
import {useState} from "react";

export const Routing = () => {
  const { user, login } = useAuth();
  const [token , setToken] = useState('');
  return (
    <BrowserRouter basename="/admin">
      {user?.isAuthenticated ?
          <Box
            sx={{
              display: 'grid',
              height: '100%',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gap: 3,
              gridTemplateRows: 'auto 1fr min-content',
              gridTemplateAreas: `
                "header header header header header"
                "sidebar main main main main"
                "footer footer footer footer footer"
              `,
            }}
          >
            <Box sx={{ gridArea: 'header' }}>
              <ResponsiveAppBar/>
            </Box>
            <Box sx={{ gridArea: 'main', overflow: 'auto'}}>
              <Routes>
                <Route path='/' element={<HomePage/>}></Route>
                <Route path='/users' exact element={<UsersPage/>}></Route>
                <Route path='/logs' exact element={<LogsPage/>}></Route>
                <Route path='/newUser' element={<SitePage/>}></Route>
                <Route path='/sessions' exact element={<SessionsPage/>}></Route>
                <Route path='/blacklist' exact element={<BlacksPage/>}></Route>
              </Routes>
            </Box>
            <Box sx={{ gridArea: 'sidebar' }}>
              <Navbar/>
            </Box>
            <Box sx={{ gridArea: 'footer', display: 'flex', alignItems: 'center', px: '20px' }}>
              <span style={{fontSize: '12px'}}>Steam Chat W.</span>
              <Box sx={{ width: 80, ml: 'auto', p:1 }}><a href='#'><img src="https://vlasovdev.site/logo.svg"/></a></Box>
            </Box>
          </Box>
         : 
        <Box sx={{ height: '100%', display: 'flex', flexFlow: 'column', gap: '20px', alignItems: 'center', justifyContent: 'center'}}>
          <Input label={'Token'} value={token} onChange={(e) => setToken(prev => e.target.value) } paceholder="Token"/>
          <Button onClick={() => login(token)}>Login</Button>
        </Box> 
        }
    </BrowserRouter>
  )
}