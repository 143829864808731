import React, { useEffect, useState } from 'react';
import {Avatar, Paper} from '@mui/material';
import {
	DataGrid, GridToolbar,
	GridToolbarContainer,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Textarea from "../components/Textarea";
import Button from "../components/Button";
import {addUsersBlack, getUserBlack} from "../api";
import {enqueueSnackbar} from "notistack";

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	);
}

export const BlacksPage = () => {
	const [steamIds, setSteamIds] = useState('')
	const columns = [
		{
			field: 'owner',
			headerName: 'Name',
			width: 200,
			editable: false,
			valueGetter: (params) => {
				return `${params?.row?.ownerId?.name}`;
			},
		},
		{
			field: 'steamId',
			headerName: 'SteamID',
			sortable: true,
			width: 200,
		},
		{
			field: 'createdAt',
			headerName: 'Date',
			sortable: true,
			width: 200,
		}
	];
	const [rows, setRows] = useState([]);
	useEffect(() =>  {
		getUserBlack().then(res => {
		  setRows(res?.users)
		}).catch(() => {
		  setRows([])
		})
	}, [])

	const addUsersAction = async () => {
		const query = await addUsersBlack(steamIds);
		if(query.success) {
			getUserBlack().then(res => {
				setRows(res?.users)
			}).catch(() => {
				setRows([])
			})
			return enqueueSnackbar('Done.', { variant: 'success' })

		}
		enqueueSnackbar(query.message, { variant: 'error' })
	}

	return(<>
			<Paper sx={{ margin: '10px',  padding: '20px'}}>
				<Textarea value={steamIds} onChange={(e) => setSteamIds(e.target.value)} sx={{margin: '20px'}} label={'Import blacklist'}></Textarea>
				<Button onClick={addUsersAction} width={"100%"}>Add</Button>
			</Paper>
			<Paper sx={{height: '100%', padding: '20px'}}>
				<DataGrid
					sx={{ mt: '10px'}}
					rows={rows}
					columns={columns}
					options={{
						enableNestedDataAccess: '.'
					}}
					getRowId={(row) => row?._id}
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
				/>
			</Paper>
		</> )
}