import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Axios } from '../libs/axios';

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useAuthProvide();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvide = () => {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState({
    nickname: '',
    roles: [],
    avatar: '',
    isAuthenticated: false,
    isActive: false,
    permissions: {},
  });

  const getSession = useCallback(async () => {
   try {
     const query = await Axios.get(`/auth/getSession`);
     if (query.data.isAuthenticated) {
       return setUser({ ...query.data.user, isAuthenticated: query.data.isAuthenticated });
     }
     return false;
   } catch (e) {
     console.log(e)
     return false
   }
  }, []);
  const login = async (username) => {
    const query = await Axios.post(`/auth/local`, { username, password: '123' });
    if (query.data.isAuthenticated) {
      await getSession();
    }
    return query.data;
  };
  const logout = () => {
    const s = '';
    return false;
  };
  useEffect(() => {
    getSession().finally(() => setIsReady(true));
  }, [getSession]);
  return {
    user, login, logout, isReady, getSession, setUser
  };
};

export default useAuth;
