import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomButton from '../components/Button';
import CustomInput from '../components/Input';
import {newUser} from '../api';
import { useSnackbar } from "notistack";

export const SitePage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(
    {
      name: '',
      token: ''
    })
  const changeForm = (val, name) => {
    setFormData(prevState => ({...prevState, [name]: val}))
  }

  const action = async () => {
    const query = await newUser(formData);
    if(query.success) {
      return enqueueSnackbar('Done.', { variant: 'success' })
    }
    enqueueSnackbar(query.message, { variant: 'error' })
  }
  return(
    <Paper sx={{ p: 3, height: '100%', overflow: 'auto'}}>
      <Box display={'grid'} gap={'20px'}>
        <Typography letterSpacing={'-0.05em'} variant="h5"><strong>New user</strong></Typography>
        <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: '300px 300px'}}>
          <CustomInput onChange={(e) => changeForm(e.target.value, 'name')} value={formData.name} label={'Name'} name="name" placeholder={'Anatoly'} type="text"/>
          <CustomInput onChange={(e) => changeForm(e.target.value, 'token')} value={formData.token} label={'Token'} name="token" placeholder={'123ABCD'} type="text"/>
        </Box>
        <CustomButton onClick={action} sx={{alignSelf: 'flex-end'}}>Add</CustomButton>
    </Box>
    </Paper>
  )
}