import {Paper, Box, Chip, Switch} from '@mui/material';
import Typography from '@mui/material/Typography';
import { CheckCircleIcon, XCircleIcon } from '@primer/octicons-react';
import {styled} from "@mui/system";
import {useEffect, useState} from "react";
import useAuth from "../hooks/AuthHook";
import {editUserOptions} from "../api";
import {enqueueSnackbar} from "notistack";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
export const HomePage = () => {
  const { user, setUser } = useAuth()
  const [settings, setSettings] = useState({
    useOtherList: false,
    accessToList: false,
    useList: false,
  })
  useEffect(() => {
    setSettings((prev) => ({...prev, ...user}))
  }, [user]);
  const changeInput = async (state, el) => {
    const query = await editUserOptions({[el]: state});
    if(query.success) {
      setSettings(prev => ({...prev, [el]: state}));
      setUser(prev => ({...prev, [el]: state }))
      return enqueueSnackbar('Done.', { variant: 'success' });
    }
    enqueueSnackbar(query.message, { variant: 'error' })
  }
  return (
    <Paper sx={{ height: '100%', p: 3}}>
      <Box display={'grid'} gap={'20px'}>
          <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Welcome.</strong></Typography>
          <Box sx={{display: 'grid', gridAutoFlow: 'column', gap: 2}}>
            <Typography letterSpacing={'-0.05em'} variant="h2"><strong>Steam Chat W.</strong></Typography>
          </Box>
      <Box maxWidth={'500px'} mt={'50px'} gap={'10px'} display={'grid'}>
        <Box justifyContent={'space-between'} display={'flex'} gap={'20px'}>
          <Typography variant={'span'}><strong>Use the blacklist of other users</strong></Typography>
          <AntSwitch sx={{mx: 3}} onChange={(e) => (changeInput(e.target.checked, 'useOtherList'))} value={settings?.useOtherList ?? true} checked={settings?.useOtherList ?? true} inputProps={{ 'aria-label': 'ant design' }} />
        </Box>
        <Box justifyContent={'space-between'} display={'flex'} gap={'20px'}>
          <Typography variant={'span'}><strong>Grant access to your blacklist to other users</strong></Typography>
          <AntSwitch sx={{mx: 3}} onChange={(e) => (changeInput(e.target.checked, 'accessToList'))} value={settings?.accessToList ?? true} checked={settings?.accessToList ?? true} inputProps={{ 'aria-label': 'ant design' }} />
        </Box>
        <Box justifyContent={'space-between'} display={'flex'} gap={'20px'}>
          <Typography variant={'span'}><strong>Use blacklist</strong></Typography>
          <AntSwitch sx={{mx: 3}} onChange={(e) => (changeInput(e.target.checked, 'useList'))} value={settings?.useList ?? true} checked={settings?.useList ?? true} inputProps={{ 'aria-label': 'ant design' }} />
        </Box>
      </Box>
      </Box>
    </Paper>
  )
}