import React, { useEffect, useState } from 'react';
import {Avatar, Paper} from '@mui/material';
import {
	DataGrid, GridToolbar,
	GridToolbarContainer,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import {getLogs} from "../api";

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	);
}

export const LogsPage = () => {
	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			width: 200,
			editable: false,
		},
		{
			field: 'steamId',
			headerName: 'SteamID',
			sortable: true,
			width: 200,
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: true,
			width: 300,
		},
		{
			field: 'createdAt',
			headerName: 'Date',
			sortable: true,
			width: 200,
		}
	];
	const [rows, setRows] = useState([]);
	useEffect(() =>  {
		getLogs().then(res => {
		  setRows(res?.logs)
		}).catch(() => {
		  setRows([])
		})
	}, [])

	return(<Paper sx={{height: '100%'}}>
		<DataGrid
			rows={rows}
			columns={columns}
			getRowId={(row) => row?._id}
			slots={{ toolbar: GridToolbar }}
			slotProps={{
				toolbar: {
					showQuickFilter: true,
				},
			}}
		/>
	</Paper>)
}