import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.shape.borderRadius[0],
        }),
        nav: ({ ownerState, theme }) => ({
          borderRadius: theme.shape.borderRadius[0],
          background: theme.palette.background.default
        }),
      },
      variants: [{
        props: { variant: 'contained' },
        style: ({ ownerState, theme }) => ({
          boxShadow: theme.shadows[1],
        }),
      },{
        props: { color: 'success', variant: 'contained' },
        style: ({ ownerState, theme }) => ({
          boxShadow: 'rgb(84 214 44 / 24%) 0px 8px 16px 0px',
        }),
      }]
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c2c2c',
      contrastText: '#ececec',
    },
    success: {
      main: 'rgb(84, 214, 44)'
    },
    background: {
      default: '#181818',
      secondary: '#181818',
      paper: '#181818',
    }
  },
  shape: {
    borderRadius: ["8px", "16px"]
  },
  shadows: [
    "rgb(145 158 171 / 20%) 0px 5px 6px -3px, rgb(145 158 171 / 14%) 0px 9px 12px 1px, rgb(145 158 171 / 12%) 0px 3px 16px 2px",
    "rgb(145 158 171 / 20%) 0px 10px 14px -6px, rgb(145 158 171 / 14%) 0px 22px 35px 3px, rgb(145 158 171 / 12%) 0px 8px 42px 7px"
  ]
})

export {
  defaultTheme,
}